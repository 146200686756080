import Dialog from '@/components/dialog'
import DialogTemplate3 from '@/components/dialog/DialogTemplate3'
import Wallet from '@/components/base/Wallet'
import WalletTemplate3 from '@/components/base/template/WalletTempate3'
import ToolbarTemplate1 from '@/components/base/template/ToolbarTemplate1'
import ToolbarTemplate3 from '@/components/base/template/ToolbarTemplate3'
import { mapActions, mapGetters, mapState } from 'vuex'
import { resStatus } from '@/utils/resUtils'
import { clickEventInterval } from '@/utils/event'

export default {
  components: {
    Dialog,
    DialogTemplate3,
    Wallet,
    WalletTemplate3,
    ToolbarTemplate1,
    ToolbarTemplate3,
  },

  data: () => ({
    valid: true,
    pwdValid: true,

    form: {
      bank_no: '', // 銀行卡號
      amount: '', // 提款金額
      withdrawal_password: '', // 提款密碼
    },

    userBankCardList: [],
    userBaseInfoStatus: false,

    amount: {
      left_amount: 0,
      left_count: 0,
      max_amount: 0,
      min_amount: 0,
    },

    dialog: {
      status: false,
      type: '',
    },

    // 執行過後，是否可以再打 api
    eventLaunch: {
      userWithdrawals: null,
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'currencySymbol', 'currencySymbol_', 'theme']),
    ...mapState({
      main: state => state.wallet.main,
      locked: state => state.wallet.locked,
      settings: state => state.websiteData.settings,
    }),

    rules() {
      return {
        amount: {
          required: value => !!value || this.$t('form.missAmount'),
          // 超過中心錢包
          overBlance: val =>
            val <= Number(this.main) ||
            this.$t('form.errorAmountNoMoney'),

          // 超過今日可用額度
          overCount: val =>
            val <= this.amount.left_amount ||
            this.$t('form.errorAmountDailyLimit'),

          min: value =>
            value >= this.amount.min_amount ||
            this.$t('form.errorAmountOutofRange', {
              min: this.amount.min_amount,
              max: this.amount.max_amount,
            }),

          max: value =>
            value <= this.amount.max_amount ||
            this.$t('form.errorAmountOutofRange', {
              min: this.amount.min_amount,
              max: this.amount.max_amount,
            }),
        },
        bankCardNo: {
          required: value => !!value || this.$t('form.missCardNo'),
        },
        pwd: {
          required: val => !!val || this.$t('form.missPassword'),
        },
      }
    },
  },

  watch: {
    userBaseInfoStatus(status) {
      if (status) return this.setDialogState(true, 'baseInfoHaveToCheck')
    },
  },

  mounted() {
    this.set_toolbar_title(this.$t('global.withdraw'))
    this.getUserBankList()

    // 初始化 延遲打 api
    Object.keys(this.eventLaunch)
      .forEach(index => {
        this.eventLaunch[index] = clickEventInterval(this.settings.click_interval)
      })
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_user_bankcard_list',
      'user_withdrawals',
      'show_alert',
      'get_user_balance',
    ]),

    /**
     * 成功取得 取得使用者銀行卡列表
     * @date 2021-03-31
     * @param {object} 銀行卡列表資料
     */
    getUserBankListSuccess(data) {
      const {
        bankcards,
        completed_baseinfo,
        left_amount,
        left_count,
        max_amount,
        min_amount,
      } = data

      this.userBankCardList = bankcards
      this.userBaseInfoStatus = completed_baseinfo !== 'Y'
      this.amount.left_count = left_count
      this.amount.left_amount = left_amount
      this.amount.max_amount = max_amount
      this.amount.min_amount = min_amount
    },

    /**
     * 取得使用者銀行卡列表
     * @date 2021-03-31
     */
    async getUserBankList() {
      const userBankCardList = await this.get_user_bankcard_list()
      resStatus(userBankCardList, this.getUserBankListSuccess)
    },

    setUserBankCardNumber(bank_no) {
      this.form.bank_no = bank_no
    },

    setDialogState(status = false, typeName = '') {
      if (!status) {
        this.$refs.pwdForm.reset()
      }
      this.dialog.status = status
      this.dialog.type = typeName
    },

    /**
     * 使用者取款 (成功)
     * @date 2021-08-04
     */
    userWithdrawalsSuccess() {
      this.show_alert({
        icon: 'done',
        text: this.$t('global.withdraw') + this.$t('global.success'),
      })
      this.getAllWallet()
      this.getUserBankList()
      this.setDialogState()
      this.$refs.form.reset()
    },

    /**
     * 使用者取款 (失敗)
     * @date 2021-08-04
     */
    userWithdrawalsFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('global.withdraw') + this.$t('global.fail'),
      })
      this.setDialogState()
      this.$refs.form.reset()
    },

    /**
     * 使用者取款
     * @date 2021-08-04
     * @param {object} form
     */
    async userWithdrawals(form) {
      // 狀態未初始化 不可打
      if (!this.eventLaunch.userWithdrawals.getStatus()) return false
      // 數秒後回複狀態
      this.eventLaunch.userWithdrawals.setStatus(false)

      const res = await this.user_withdrawals(form)
      resStatus(res, this.userWithdrawalsSuccess, this.userWithdrawalsFail)
    },

    async getAllWallet() {
      await this.getUserWallet('player_balance')
      await this.getUserWallet('locked_balance')
    },

    /**
     * 取得使用者錢包 (中心錢包 / 鎖定錢包)
     * @date 2021-03-31
     * @param {string} walletcode "locked_balance"：鎖定錢包 - "player_balance"：主錢包、中心錢包 - "gf_ia_esport"：小艾電競錢包
     */
    async getUserWallet(walletcode) {
      await this.get_user_balance({ walletcode })
    },
  },
}
