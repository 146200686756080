<template>
  <!-- 存款 -->
  <div class="pt-56">
    <v-container>
      <v-row>
        <!-- 中心錢包 -->
        <v-col cols="12">
          <Wallet />
          <v-divider class="mt-3" />
        </v-col>

        <!-- 存款方式 -->
        <v-col
          cols="12"
          class="pt-0 px-4"
        >
          4
          <div class="withdrawalToBank__title title--text font-weight-medium">
            {{ $t("myCenter.paymentMethod") }}
          </div>

          <!-- payment list -->
          <v-container class="mt-4">
            <v-row
              :class="[{ 'payment__list-hidden': paymenetListAreaHidden }]"
            >
              <!-- 使用者付款列表 -->
              <v-col
                v-for="payment in userPaymentList"
                :key="payment._id"
                cols="3"
                class="px-0 py-2 d-flex flex-column justify-center align-center"
                @click="
                  setPayType(
                    payment.code,
                    payment.currency_symbol,
                    payment.currency,
                    payment.payment_platform
                  )
                "
              >
                <img
                  class="rounded caption"
                  :class="[
                    activePayType === payment.code ? '' : 'not-active-type',
                  ]"
                  :src="payment.image"
                  :alt="payment.name"
                  height="72"
                  width="72"
                >

                <span
                  class="caption mt-1 font-weight-medium"
                  :class="[
                    activePayType === payment.code
                      ? 'primary--text'
                      : 'subtitle--text',
                  ]"
                >
                  {{ payment.name }}
                </span>
              </v-col>

              <!-- 銀行卡轉卡 -->
              <template v-if="bankCard[0]">
                <v-col
                  cols="3"
                  class="
                    px-0
                    py-2
                    d-flex
                    flex-column
                    justify-center
                    align-center
                  "
                  @click="setPayType('BANK', bankCard[0].currency_symbol)"
                >
                  <img
                    class="rounded caption"
                    :class="[form.paytype === 'BANK' ? '' : 'not-active-type']"
                    :src="baseImgUrl + bankCard[0].image"
                    :alt="bankCard[0].name"
                    height="72"
                    width="72"
                  >

                  <span
                    class="caption mt-1 font-weight-medium"
                    :class="[
                      form.paytype === 'BANK'
                        ? 'primary--text'
                        : 'subtitle--text',
                    ]"
                  >
                    {{ bankCard[0].name }}
                  </span>
                </v-col>
              </template>
            </v-row>

            <!-- 顯示更多 arrow icon (show / hidden) -->
            <v-row v-if="paymentListLength">
              <v-col
                cols="12"
                class="pa-0 text-center pt-0"
              >
                <div class="arrow">
                  <div class="d-flex justify-center">
                    <v-img
                      :class="[{ 'transform-rotate': !paymenetListAreaHidden }]"
                      max-height="40"
                      max-width="50"
                      src="@/assets/icons/animationArrow.gif"
                      @click="paymenetListAreaHidden = !paymenetListAreaHidden"
                    />
                  </div>
                </div>

                <v-divider class="mt-3" />
              </v-col>
            </v-row>

            <!-- 管道區塊 pipeline -->
            <v-row
              v-show="pipelineList.length > 0"
              class="card1 rounded"
            >
              <!-- 第一層 標題 -->
              <v-col
                cols="12"
                class="pb-0 subtitle-2"
              >
                {{ $t(pipeline.lv1.title[0]) }}
              </v-col>

              <!-- 第一層 列表 -->
              <v-col
                v-for="(channel, index) in pipelineList"
                :key="channel._id"
                cols="3"
                class="px-0 py-2 d-flex flex-column justify-center align-center"
                @click="setPipelineItem(channel, index)"
              >
                <!-- <img
                  class="rounded caption"
                  :class="[
                    activePipelineItem ===
                      channel.code + channel.paytype_no + index
                      ? ''
                      : 'not-active-type',
                  ]"
                  :src="channel.image"
                  :alt="channel.name"
                  height="72"
                  width="72"
                > -->
                <span
                  class="rounded caption"
                  :class="[
                    activePipelineItem ===
                      channel.code + channel.paytype_no + index
                      ? ''
                      : 'not-active-type',
                  ]"
                >
                  {{ channel.name }}
                </span>

                <span
                  class="caption mt-1 font-weight-medium"
                  :class="[
                    activePipelineItem ===
                      channel.code + channel.paytype_no + index
                      ? 'primary--text'
                      : 'subtitle--text',
                  ]"
                >
                  {{ $t("deposit.channel") + " " + (index + 1) }}
                </span>
              </v-col>

              <!-- 第二層 標題 -->
              <v-col
                v-if="false"
                cols="12"
                class="pb-0 subtitle-2"
              >
                {{ $t(pipeline.lv2.title[0]) }}
              </v-col>

              <template v-if="false">
                <!-- 第二層 列表 -->
                <v-col
                  v-for="payment in userPaymentList"
                  :key="payment._id"
                  cols="4"
                  class="py-2 d-flex flex-column justify-center align-center"
                  @click="setPayType(payment.code, payment.currency_symbol, payment)"
                >
                  <div class="w-100 text-center rounded card1 py-1">
                    <span
                      class="caption mt-1 font-weight-medium"
                      :class="[
                        form.paytype === payment.code
                          ? 'primary--text'
                          : 'subtitle--text',
                      ]"
                    >
                      {{ payment.name }}
                    </span>
                  </div>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-col>

        <!-- 存款金額 -->
        <v-col
          v-show="showAmountArea"
          cols="12"
          class="pt-0"
        >
          <v-divider class="my-3" />

          <div
            class="withdrawalToBank__title title--text font-weight-medium px-1"
          >
            {{ $t("global.depositAmount") }}
          </div>

          <v-container>
            <v-row>
              <v-col
                v-show="activePaymentType !== 'option'"
                cols="5"
                class="px-0 pb-0 px-1"
              >
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <!-- 輸入存款金額 -->
                  <v-text-field
                    v-model.number.trim="form.amount"
                    max-height="36"
                    dense
                    :placeholder="$t('form.missAmount')"
                    single-line
                    outlined
                    required
                    inputmode="numeric"
                    clear-icon="mdi-close-circle"
                    onkeyup="this.value=this.value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                    clearable
                    type="number"
                    :rules="[
                      rules.amount.required,
                      rules.amount.min,
                      rules.amount.max,
                    ]"
                  />
                </v-form>
              </v-col>

              <!-- 存款範圍 -->
              <v-col
                v-if="activePaymentType === 'range'"
                cols="7"
                class="px-0 pb-0 caption comment--text d-flex"
              >
                <div class="d-flex flex-column justify-start pr-5">
                  <span>
                    {{ $t("myCenter.singleDepositRange") }}
                  </span>
                  <span>
                    {{
                      activeCurrencySymbol +
                        amount.min_amount +
                        "~" +
                        activeCurrencySymbol +
                        amount.max_amount
                    }}
                  </span>
                </div>
                <div
                  v-if="activeCurrency === 'USDT' ||activePipeline && activePipeline.currency === 'USDT'"
                  class="d-flex flex-column justify-start"
                >
                  <span>{{ $t("myCenter.rate") }}</span>
                  <span>{{ usdtRate }}</span>
                </div>
              </v-col>

              <v-col
                v-if="activePaymentType === 'range'"
                cols="12"
                class="pa-0 caption px-1"
              >
                {{ $t('myCenter.decimalPointMode') }}
              </v-col>

              <!-- 快捷鍵 存款金額 -->
              <v-col
                cols="12"
                class="pa-0"
              >
                <!-- 支付類型 (range) 不顯示 此區不會再出現-->
                <v-container v-if="false">
                  <v-row>
                    <v-col
                      v-for="item in fastAmountBtn"
                      :key="item"
                      cols="4"
                      class="py-1 px-1"
                    >
                      <v-btn
                        active-class="test"
                        block
                        color="card1"
                        @click="plusAmount(item)"
                      >
                        <span class="title--text caption">
                          {{ `+${item}` }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

                <!-- 支付類型 (option) -->
                <v-container v-if="activePaymentType === 'option'">
                  <v-row class="mt-1">
                    <v-col
                      v-for="item in fastAmountBtnForOption"
                      :key="item"
                      cols="4"
                      class="py-1 px-1"
                    >
                      <v-btn
                        depressed
                        active-class="test"
                        block
                        color="card1"
                        :style="form.amount === item ? activePaymentItem : ''"
                        @click="typeOptionSetAmount(item)"
                      >
                        <span
                          class="caption"
                          :class="
                            form.amount === item
                              ? 'primary--text'
                              : 'title--text'
                          "
                        >
                          {{ item }}
                        </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <!-- 送出表單 -->
        <v-col
          v-show="showAmountArea"
          cols="12"
          class="pt-0 px-4 mt-3"
        >
          <v-btn
            :disabled="!valid"
            height="44"
            block
            color="primary"
            @click="deposit(form)"
          >
            <span class="subtitle-1 btnText--text">
              {{ $t("global.deposit") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- 彈窗 Dialog -->
    <Dialog :dialog="dialog.status">
      <!-- 如果非銀行轉帳 (QR Code 顯示掃碼) -->
      <template v-if="dialog.type === 'url'">
        <div
          slot="title"
          class="w-100 text-center"
        >
          <div class="text-h6 title--text">
            {{ $t("global.deposit") + $t("global.info") }}
          </div>

          <div class="caption secondary--text mt-4 text-left">
            {{
              $t("flashMessage.applySuccess", {
                action: $t("global.deposit"),
              })
            }}
          </div>
          <!-- QR CODE 工具 -->
          <div>
            <!-- <QrcodeVue
              :value="dialog.content"
              class="mt-9"
              size="166"
              level="L"
              foreground="#1563FF"
            /> -->
            <!-- 前往存款 (文字) -->
            <a :href="dialog.content">
              {{ $t('deposit.goToDeposit') }}
            </a>
          </div>
        </div>

        <div slot="text">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="caption subtitle--text pa-0 text-center"
              >
                <v-btn
                  color="primary"
                  text
                  @click="closeDialog"
                >
                  {{ $t("global.confirmation") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>

      <!-- 如果銀行轉帳 (轉帳資訊) -->
      <template v-else>
        <!-- header -->
        <div
          slot="title"
          class="w-100 text-center"
        >
          <div class="text-h6 title--text">
            {{ $t("global.deposit") + $t("global.info") }}
          </div>

          <div class="amount primary--text mt-3">
            <span>
              {{ $t("global.dollar") }}
            </span>
            <span id="amount">
              {{ dialog.content.amount }}
            </span>
            <v-btn
              max-height="30"
              max-width="30"
              icon
              @click="copyText('amount')"
            >
              <Icon
                data="@icon/copy.svg"
                width="16"
                height="16"
                class="card1--text"
              />
            </v-btn>
          </div>

          <div class="caption secondary--text">
            {{ $t("global.remind") }}
          </div>
        </div>

        <!-- body -->
        <div slot="text">
          <v-container>
            <v-row class="px-6">
              <!-- 轉帳銀行 -->
              <v-col
                cols="3"
                class="caption subtitle--text px-0 d-flex align-center"
              >
                {{ $t("deposit.bank_name") }}
              </v-col>
              <v-col
                id="bank_name"
                cols="7"
                class="title--text px-0 d-flex align-center"
              >
                {{ dialog.content.bank_name }}
              </v-col>
              <v-col
                cols="2"
                class="d-flex align-center justify-end pa-0"
              >
                <v-btn
                  max-height="30"
                  max-width="30"
                  icon
                  @click="copyText('bank_name')"
                >
                  <Icon
                    data="@icon/copy.svg"
                    width="16"
                    height="16"
                    class="card1--text"
                  />
                </v-btn>
              </v-col>

              <!-- 銀行帳號 -->
              <v-col
                cols="3"
                class="caption subtitle--text px-0 d-flex align-center"
              >
                {{ $t("deposit.bank_no") }}
              </v-col>
              <v-col
                id="bank_no"
                cols="7"
                class="title--text px-0 d-flex align-center"
              >
                {{ dialog.content.bank_no }}
              </v-col>
              <v-col
                cols="2"
                class="d-flex align-center justify-end pa-0"
              >
                <v-btn
                  max-height="30"
                  max-width="30"
                  icon
                  @click="copyText('bank_no')"
                >
                  <Icon
                    data="@icon/copy.svg"
                    width="16"
                    height="16"
                    class="card1--text"
                  />
                </v-btn>
              </v-col>

              <!-- 轉帳姓名 -->
              <v-col
                cols="3"
                class="caption subtitle--text px-0 d-flex align-center"
              >
                {{ $t("deposit.transfer_name") }}
              </v-col>
              <v-col
                id="transfer_name"
                cols="7"
                class="title--text px-0 d-flex align-center"
              >
                {{ dialog.content.transfer_name }}
              </v-col>
              <v-col
                cols="2"
                class="d-flex align-center justify-end pa-0"
              >
                <v-btn
                  max-height="30"
                  max-width="30"
                  icon
                  @click="copyText('transfer_name')"
                >
                  <Icon
                    data="@icon/copy.svg"
                    width="16"
                    height="16"
                    class="card1--text"
                  />
                </v-btn>
              </v-col>

              <!-- 開戶地址 -->
              <v-col
                cols="3"
                class="caption subtitle--text px-0 d-flex align-center"
              >
                {{ $t("deposit.transfer_address") }}
              </v-col>
              <v-col
                id="transfer_address"
                cols="7"
                class="title--text px-0 d-flex align-center caption"
              >
                {{ addressLengthFormat }}
              </v-col>
              <v-col
                cols="2"
                class="d-flex align-center justify-end pa-0"
              >
                <v-btn
                  max-height="30"
                  max-width="30"
                  icon
                  @click="copyText('transfer_address')"
                >
                  <Icon
                    data="@icon/copy.svg"
                    width="16"
                    height="16"
                    class="card1--text"
                  />
                </v-btn>
              </v-col>

              <!-- 備註 -->
              <v-col
                cols="3"
                class="caption subtitle--text px-0 d-flex align-center"
              >
                {{ $t("deposit.paycode") }}
              </v-col>
              <v-col
                id="paycode"
                cols="7"
                class="title--text px-0 d-flex align-center"
              >
                {{ dialog.content.paycode }}
              </v-col>
              <v-col
                cols="2"
                class="d-flex align-center justify-end pa-0"
              >
                <v-btn
                  max-height="30"
                  max-width="30"
                  icon
                  @click="copyText('paycode')"
                >
                  <Icon
                    data="@icon/copy.svg"
                    width="16"
                    height="16"
                    class="card1--text"
                  />
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                class="caption subtitle--text pa-0 text-center"
              >
                <v-btn
                  color="primary"
                  text
                  @click="closeDialog"
                >
                  {{ $t("global.confirmation") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import depositMixin from '../mixins/depositMixin'

export default {
  mixins: [depositMixin],
}
</script>

<style lang="scss" scoped>
.payment__list-hidden {
  overflow: hidden;
  height: 225px;
}

.transform-rotate {
  transform: rotate(180deg);
}

.not-active-type {
  opacity: 0.2;
}
</style>
